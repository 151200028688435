import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/',
    redirect: '/room'
  },
  {
    path: '/room',
    name: 'Room',
    component: () => import('../views/Room')
  },
  {
    path: '/broad',
    name: 'Broad',
    component: () => import('../views/Broad')
  },
  {
    path: '/adm',
    name: 'Admin',
    component: () => import('../views/Admin')
  },
  {
    path: '/adm/delete',
    name: 'Delete',
    component: () => import('../views/Delete')
  },
  {
    path: '/man',
    name: 'Manager',
    component: () => import('../views/Manager'),
  },
  {
    path: '/man/download',
    name: 'Download',
    component: () => import('../views/Download')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
