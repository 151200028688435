<template>
  <div id="app" class="text-gray-800">
<!--    <div id="nav" class="flex space-x-4 p-4 bg-gray-100">-->
<!--      <router-link to="/room" class="font-bold text-gray-700 hover:text-gray-900">Room</router-link>-->
<!--    </div>-->
      <router-view/>
  </div>
</template>

<script>
export default {
    name: 'App',
    data: function() {
        return {
        }
    },
    methods: {

    },
}
</script>

<style>
#app {
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
