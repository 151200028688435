import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import io from 'socket.io-client'
import ss from 'socket.io-stream'
import adapter from 'webrtc-adapter'
import axios from "axios";
import './assets/css/tailwind.css'
import '@fortawesome/fontawesome-free/css/all.css'

// const socket = io("https://hmtalk.psymule.com")
// const socket = io(":8080")
// const socket = io(":5000")

Vue.prototype.$adapter = adapter;
// Vue.prototype.$socket = socket;
Vue.prototype.$sstream = ss;
Vue.prototype.$http = axios;
Vue.prototype.$debug = (location.host !== "rtc.e-human.co.kr" && location.host !== "record.e-human.co.kr");
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
